import { query } from "atomic-layout"
import { createGlobalStyle, css } from "styled-components"
import { styledScaledHeadings } from "../utils/typography"
import { collapseWidth } from "../components/organisms/Navigation"

export const SROnly = css`
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
`

export const GlobalStyles = createGlobalStyle`
  :root {
    --max-body-width: 1440px;
    --section-spacing: 5rem;
    --section-side-padding: 1.5rem;
    --gutter: 1.111rem;
    --menu-item-spacing: 1.5rem;
    --base-font-size-percent: 100%;
    --line-height: 1.45;
    --input-bottom-margin: 40px;
    --body-font-family: Karla, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    --heading-font-family: Karla, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    --body-text-color: #4E4B48;
    --body-text-color-secondary: #4E4B48;
    --callout-text-size: 1.333rem;
    --roundness: 32px;
    --primary-color: ${({ theme }) => theme.primaryColor};
    --secondary-color: ${({ theme }) => theme.secondaryColor};
    --tertiary-color: ${({ theme }) => theme.tertiaryColor};
    //--modular-scale-ratio: 1.2;
    --paragraph-margin-block-end: 1.3em;
    --heading-margin-block-end: 0.3em;
    --link-color: ${({ theme }) => theme.primaryColor};
    --backdrop-offset: 32px;

    @media ${query({ from: "sm" })} {
      --heading-margin-block-end: 0.4926em;
    }

    @media ${query({ from: "sm" })} {
      --section-spacing: 6rem;
    }
    @media ${query({ from: "lg" })} {
      --backdrop-offset: 72px;
      --paragraph-margin-block-end: 1.889em;
    }
    @media ${query({ from: "xl" })} {
      --section-spacing: 10rem;
    }
  }

  html {
    font-size: 18px;
    //scroll-behavior: smooth;
  }

  body {
    margin: 0 auto;
    max-width: var(--max-body-width);
    //overflow-x: hidden;
    font-family: var(--body-font-family);
    background-color: #FCFAF4;
    color: var(--body-text-color);
    -webkit-font-smoothing: antialiased;
  }

  .sb-show-main.sb-main-fullscreen {
    margin: 0 auto !important;
  }

  body.has-banner {
    // This fixes the issue where the top banner can cover the anchor headings
    // TODO check for fixed header
    h1::before, h2::before, h3::before {
      display: block;
      content: " ";
      margin-top: -60px;
      height: 60px;
      visibility: hidden;
      pointer-events: none;
    }
  }

  * {
    box-sizing: border-box;
  }

  a {
    color: var(--link-color);
    text-decoration: none;
  }

  img {
    display: block;
    width: 100%;
  }

  p {
    line-height: var(--line-height);
    margin-block-start: 0;
    font-size: 1rem;
    margin: var(--paragraph-margin-block-end) 0;
    letter-spacing: -0.02em;
  }

  p:last-child, ul:last-child {
    margin-bottom: 0;
  }

  p + ul, p + ul:last-child {
    margin-top: calc(var(--paragraph-margin-block-end) * -0.8);
  }

  h1 + p, h2 + p, h3 + p, h4 + p, h5 + p, h6 + p {
    // We use the margin of the heading
    margin-top: 0;
  }

  p + h1, p + h2, p + h3, p + h4, p + h5, p + h6 {
    // We use the margin of the heading
    @media ${query({ to: "lg" })} {
      margin-top: calc(var(--paragraph-margin-block-end) * 1.5);
    }
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: var(--heading-font-family);
    font-weight: 800;
    line-height: 120%;
    letter-spacing: -0.04em;
    margin: 0;
    margin-block-end: var(--heading-margin-block-end);
  }

  small {
    font-size: 13.5px;
    line-height: 150%;
  }

  .superscript {
    vertical-align: super;
    font-size: 0.7em;
    line-height: 0;
  }

  span.overline {
    display: block;
    font-weight: bold;
    font-size: 13.5px;
    line-height: 150%;
    text-transform: uppercase;
    color: #C69214;
    letter-spacing: 0.03rem;
  }

  ${styledScaledHeadings()}
  ${[1, 2, 3, 4, 5, 6].reduce((prev, val) => `${prev}p ~ h${val}, ul ~ h${val}, ol ~ h${val},`, "").replace(/(^,)|(,$)/g, "")} {
    //margin-top: calc(var(--line-height) * 4rem);
  }

  fieldset {
    border: none;
    padding: 0;
    margin: 0;
  }

  label:not(.hs-form-checkbox-display):not(.hs-error-msg) {
    //display: inline-block;
    font-weight: bold;
    font-size: 1rem;
    line-height: 145%;
  }

  input:not([type='submit']):not([type='checkbox']), select, textarea {
    line-height: 100%;
    font-size: 1.333rem;
    padding: 16px;
    width: 100%;
    border: 1px solid #515151;
    border-radius: 8px;
    color: var(--body-text-color);

    &:focus {
      outline: none;
      box-shadow: 0 0 5px #99cdc1;
      border: 1px solid ${({ theme }) => theme.primaryColor};
    }
  }

  ul, ol {
    margin-top: 0;
    line-height: 150%;

    ul {
      margin-bottom: 0;
    }
  }

  input[type=checkbox] {
    vertical-align: middle;
    position: relative;
    bottom: 1px;
  }

  pre {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  #gatsby-focus-wrapper {
    position: relative;
  }

  .caret {
    &:after {
      content: url(/images/caret-mobile.svg);
      margin-left: 8px;

      @media (min-width: ${collapseWidth}) {
        content: url(/images/caret.svg);
      }
    }
  }

  .caret-before {
    &:before {
      content: url(/images/caret-mobile.svg);
      transform: rotate(180deg);
      margin-right: 8px;
      display: inline-block;
    }
  }

  .anchor {
    position: absolute;
    content: '';
    display: block;
    height: 0;
    top: -90px;
    visibility: hidden;
    pointer-events: none;
  }

  .sr-only {
    ${SROnly}
  }
`
