import React from "react"
import Layout, { Box } from "atomic-layout"
import styled from "styled-components"

const StyledSection = styled(Box)`
  position: relative;
`

export const maxWidths = ({ fluid, small, horizontalSpacing = " - calc(var(--section-side-padding) * 2)" }) => ({
  maxWidth: `calc(100%${horizontalSpacing})`,
  maxWidthSm: !fluid ? `calc(${Layout.breakpoints.sm.minWidth}${horizontalSpacing})` : "",
  maxWidthMd: !fluid ? `calc(${Layout.breakpoints.md.minWidth}${horizontalSpacing})` : "",
  maxWidthLg: !fluid ? `calc(${Layout.breakpoints.lg.minWidth}${horizontalSpacing})` : "",
  maxWidthXl: !fluid && !small ? `calc(${Layout.breakpoints.xl.minWidth}${horizontalSpacing})` : ""
})

type ContainerProps = {
  background?: string,
  children?: React.ReactNode,
  containerProps?: React.HTMLAttributes,
  innerProps?: React.HTMLAttributes,
  small?: boolean,
  fluid?: boolean,
  removeVerticalSpacing?: boolean,
  removeHorizontalSpacing?: boolean,
  className?: string,
  as?: string,
}

const Container = React.forwardRef(({
                                      background = null,
                                      children,
                                      containerProps = null,
                                      innerProps = null,
                                      small = false,
                                      fluid = false,
                                      removeVerticalSpacing = false,
                                      removeHorizontalSpacing = false,
                                      className = "",
                                      as = ""
                                    }: ContainerProps, ref) => {

  const horizontalSpacing = removeHorizontalSpacing || fluid ? "" : " - calc(var(--section-side-padding) * 2)"

  // Sometimes we use padding and other times we use margins. The reason for this is that a 
  // background requires padding in order to fill it out to the edges, but we want the 
  // collapsability of margins when no background is present.
  const needsVerticalPadding = !removeVerticalSpacing && background

  return (
    <StyledSection
      className={className}
      as={as || "section"}
      {...containerProps}
      ref={ref}
    >
      {background}
      <Box
        style={{ position: "relative" }}
        {...maxWidths({ fluid, small, horizontalSpacing })}
        marginHorizontal={"auto"}
        paddingVertical={needsVerticalPadding ? "var(--section-spacing)" : 0}
        marginVertical={!needsVerticalPadding && !removeVerticalSpacing ? "var(--section-spacing)" : 0}
        {...innerProps}
      >
        {children}
      </Box>
    </StyledSection>
  )
})

export default Container
