const pluralize = require('pluralize/pluralize')

const linkResolver = (doc) => {
  // Your link resolver
  if (doc.uid === "home") {
    return "/"
  }

  let type = ""
  if (typeof doc.type !== "undefined" && doc.type !== "page") {
    let typeParts = doc.type.split("_")
    typeParts[typeParts.length - 1] = pluralize(typeParts[typeParts.length - 1])
    type = "/" + typeParts.join("-")
  }

  const prefix = typeof doc.data !== "undefined" && doc.data.slug_prefix ? doc.data.slug_prefix + "/" : ""

  return type + "/" + prefix + doc.uid + "/"
}

exports.linkResolver = linkResolver;
