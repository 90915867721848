import styled, { css } from "styled-components"
import Link, {LinkProps} from "../Link"
import { darken } from "polished"

export const linkStyles = css`
  color: var(--primary-color);
  text-decoration: underline;

  &:hover {
    color: var(--primary-color);
  }
`

export const buttonBaseStyles = css`
  display: inline-block;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  margin-right: auto;
  border: none;
  line-height: 120%;
  transition: 0.2s;


  &:hover {
    text-decoration: none;
    color: var(--primary-color);
  }

  &[type=submit], &[type=Reset] {
    -webkit-appearance: none;
  }
`

export const primaryStyles = css`
  ${buttonBaseStyles};
  background-color: ${({ theme }) => theme.primaryColor};
  color: #fff;
  padding: 20px 52px;
  border-radius: var(--roundness);
  font-size: 18px;

  &:hover {
    background-color: ${({ theme }) => theme.secondaryColor};
    color: #fff;
  }

  &:disabled {
    background: #E9E9E9;
    color: #BEBEBE;
    cursor: not-allowed;
  }
`

export const secondaryStyles = css`
  ${buttonBaseStyles};
  background-color: transparent;
  color: var(--body-text-color-secondary);
  border-bottom: 2px solid #9E9CA0;
  text-align: left;
  display: inline-block;
  font-size: 28px;
  font-weight: normal;
  letter-spacing: -0.02em;

  &:hover {
    color: var(--primary-color);
    border-color: var(--primary-color);
  }
`

export const outlineStyles = css`
  
  border-radius: var(--roundness);

  ${buttonBaseStyles};
  background-color: transparent;
  color: #fff;
  border: 2px solid #fff;
  text-align: center;
  display: inline-block;
  padding: 12px 32px;
  letter-spacing: -0.02em;

  &:hover {
    color: #fff;
    border-color: var(--secondary-color);
    background-color: var(--secondary-color);
  }
`

export const darkStyles = css`
  border-radius: var(--roundness);

  ${buttonBaseStyles};
  background-color: transparent;
  color: var(--primary-color);
  border: 2px solid var(--primary-color);
  text-align: center;
  display: inline-block;
  padding: 12px 32px;
  letter-spacing: -0.02em;

  &:hover {
    color: #fff;
    border-color: var(--secondary-color);
    background-color: var(--secondary-color);
  }
`

interface ButtonProps extends LinkProps {
  primary?: boolean,
  secondary?: boolean,
  isLink?: boolean,
  outline?: boolean,
  dark?: boolean,
}

const Button = styled(Link)<ButtonProps>`
  transition: 0.2s background-color;
  
  &:hover {
    color: var(--primary-color);
  }
  
  ${({ primary, isLink, secondary, outline, dark }) => {
    if (isLink) {
      return linkStyles
    }

    if (primary) {
      return primaryStyles
    }

    if (secondary) {
      return secondaryStyles
    }

    if (outline) {
      return outlineStyles
    }

    if (dark) {
      return darkStyles
    }
  }}
`



export default Button
