import * as React from "react"
import {css} from "styled-components"

export const theme = {
    primaryColor: '#003057',
    secondaryColor: '#C69214',
    shadow: css`filter: drop-shadow(0px 16px 40px rgba(0, 0, 0, 0.15));`,
    modularTypeScale: {
        default: 1.15,
        md: 1.15,
        lg: 1.2,
    }, // https://type-scale.com
    breakpoints: { // min widths
        // xxl: 1920,
        xl: 1200,
        lg: 992,
        md: 768,
        sm: 576,
        xs: 575
    },
}

export default theme
