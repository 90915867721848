import theme from '../theme';
import { modularScale } from "polished";
import { query } from "atomic-layout";
import { css } from 'styled-components';


export const styledScaledHeadings = (tag = 'h') => [1, 2, 3, 4, 5, 6].map((key) => (
  css`
    ${tag}${key}{
      ${responsiveFontSize(7 - key, 1)}
    }
  `
))

export const responsiveFontSize = (steps, base, includeBottomMargin = true) => {
  const bottomMargin = includeBottomMargin ? `
    margin-block-end: var(--heading-margin-block-end);
  ` : '';

  const fontStyles = Object.keys(theme.modularTypeScale).map((breakpoint) => {
    if (breakpoint === 'default') {
      return `
            font-size: ${modularScale(steps, base, theme.modularTypeScale[breakpoint])}rem;
          `
    } else {
      return `
          @media ${query({ from: breakpoint })}{
              font-size: ${modularScale(steps, base, theme.modularTypeScale[breakpoint])}rem;
          }
          `
    }
  })

  return css`
  ${bottomMargin}
  ${fontStyles}
  `
}
