import * as React from "react"
import { Link as GatsbyLink } from "gatsby"
import { useContext } from "react"
import {TemplateContext} from "../../../layout"

export interface LinkProps {
  children: React.ReactNode,
  to?: string,
  activeClassName?: string,
  partiallyActive?: string,
  target?: string,
}

// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
const Link = ({
                children,
                to = '',
                activeClassName = "active",
                partiallyActive = false,
                target = "_self",
                ...other
              }) => {
  const template = useContext(TemplateContext)
  
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const href = to || other?.href || ''
  

  const { className = "", style = {} } = other
  
  // check if this is using the iframe template. If it 
  // is, we want all links to act like external links.
  const iframePage = template === 'page-remove-header-footer'
  
  const internal = /^\/(?!\/)/.test(href) || href.startsWith('https://www.drivercapital.ca')
  // Use Gatsby Link for internal links, and <a> for others

  const actualTarget = target ? target : '_self';
  
  // We must include target !== '_blank' here, since Gatsby Link 
  // doesn't work properly in iframes with blank target
  if (internal && target !== '_blank') {
    return (
      <GatsbyLink
        to={href.replace('https://www.drivercapital.ca', '')}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        className={className}
        style={style}
        target={actualTarget}
      >
        {children}
      </GatsbyLink>
    )
  }

  return (
    <a 
      href={href} 
      style={style} 
      className={className} 
      target={actualTarget}
    >
      {children}
    </a>
  )
}

export default Link
