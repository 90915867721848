import * as React from "react"
// import theme from "../theme"
// import PrismicFooter from './PrismicFooter';
import { graphql, StaticQuery } from "gatsby"
import { ThemeProvider } from "styled-components"
// import { defaultRebootTheme } from "styled-reboot"
import Helmet from "react-helmet"
import { GlobalStyles } from "../theme/global"
import theme from "../theme"
import SEO from "../components/atoms/SEO"

export const TemplateContext = React.createContext('page');

export default ({ children = null }) => {
  const query = graphql`
    query SiteTitleQuery {
      site {
        id
        siteMetadata {
          title
          description
        }
      }
    }
  `
  return (
    <StaticQuery
      query={`${query}`}
      render={data => (
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <SEO title={data.site.siteMetadata.title} description={data.site.siteMetadata.description} />
          <Helmet>
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
            <link href="https://fonts.googleapis.com/css2?family=Karla:wght@400;700;800&display=swap" rel="stylesheet" />
            <link rel="stylesheet" href="https://assets.drivercapital.ca/maxloan-calculator/maxloan-calculator.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <script src="https://assets.drivercapital.ca/maxloan-calculator/maxloan-calculator.js" type="module" />
          </Helmet>
          {children}
          {/*<ScrollTop/>*/}
          {/*<PrismicFooter />*/}
        </ThemeProvider>
      )}
    />
  )
}
